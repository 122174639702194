<template lang="">
 <Notes />
</template>
<script>
export default {
  name: "notes",
  components: {
    Notes: () => import("@/components/property/Notes.vue"),
  },
};
</script>
